import React, { createContext, useContext, useState } from "react";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import instance from "../../axiosInstance";
import useError from "../../hooks/useError";
import { isBase64Image } from "../../utils/forms";
import { validateAlphaNumeric, validateDecimalInput, validateEmail, validateNumericInput, validatePhoneNumber } from "../../utils/validations";

export const userContext = createContext({});
export const useUserContext = () => useContext(userContext);

let createdUserId = null;

const UserContextProvider = ({ children }) => {
  const { handleError } = useError();
  const history = useHistory();
  const [userId, setUserId] = useState("");
  const [mode, setMode] = useState("add");

  // User Details
  const [loginName, setLoginName] = useState("");
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [occupation, setOccupation] = useState("");
  const [postCode, setPostCode] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [status, setStatus] = useState("active");
  const [userImage, setUserImage] = useState("");
  const [introducer, setIntroducer] = useState(null);
  const [isVatRegistered, setIsVatRegistered] = useState(null);
  const [vatRegNumber, setVatRegNo] = useState("");

  // User Control
  const [roles, setRoles] = useState(null);
  const [userType, setUserType] = useState(null);
  const [garageType, setGarageType] = useState(null);
  const [piPrefrence, setPiPreference] = useState(false);
  const [hirePrefrence, setHirePrefrence] = useState(false);
  const [userControlsPermssions, setUserControlsPermssions] = useState([]);

  const [machineRestricted, setMachineRestricted] = useState(false);
  const [userGroup, setUserGroup] = useState(null);

  // Contact Information
  const [contactNumber, setContactNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  // Emergency Contact Details
  const [emergencyContactName, setEmergencyContactName] = useState("");
  const [emergencyMobileNumber, setEmergencyMobileNumber] = useState("");
  const [relationship, setRelationship] = useState("");
  const [recommendedBy, setRecommendedBy] = useState("");
  const [emergencyAddress, setEmergencyAddress] = useState("");
  const [dueDiligence, setDueDiligence] = useState("Complete");
  const [emergencyPostCode, setEmergencyPostCode] = useState("");

  // Banking Details
  const [bankName, setBankName] = useState("");
  const [branch, setBranch] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [cardName, setCardName] = useState("");
  const [bankingPostCode, setBankingPostCode] = useState("");

  // Repairer Banking Details
  const [repairerBankName, setRepairerBankName] = useState("");
  const [repairerCardName, setRepairerCardName] = useState("");
  const [repairerAccountNumber, setRepairerAccountNumber] = useState("");
  const [repairerPostCode, setRepairerPostCode] = useState("");

  // Attachment
  const [contractForm, setContractForm] = useState({
    name: "",
    file: "",
  });
  const [poa, setPoa] = useState({
    name: "",
    file: "",
  });
  const [poi, setPoi] = useState({
    name: "",
    file: "",
  });
  const [photograph, setPhotograph] = useState(null);
  const [attachmentTitle, setAttachmentTitle] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [attachments, setAttachments] = useState([]);

  // Additional
  const [buyingPriceAdult, setBuyingPriceAdult] = useState("");
  const [prestigeHire, setPrestigeHire] = useState("");
  const [buyingPriceMinor, setBuyingPriceMinor] = useState("");
  const [pcoOrTaxi, setPcoOrTaxi] = useState("");
  const [rtamib, setRtamib] = useState("");
  const [motorbike, setMotorbike] = useState("");
  const [standardHire, setStandardHire] = useState("");
  const [sourcePaymentDays, setSourcePaymentDays] = useState("");
  const [forPiPanel, setForPiPanel] = useState([]);
  const [forHireSupplier, setForHireSupplier] = useState([]);

  // Errors

  // User Details Errors
  const [userDetailsError, setUserDetailsError] = useState({
    loginName: "",
    password: "",
    fullName: "",
    email: "",
    contactNumber: "",
    mobileNumber: "",
    postCode: "",
  });

  const resetUserDetailsErrors = () => {
    setUserDetailsError({
      loginName: "",
      password: "",
      fullName: "",
      email: "",
      contactNumber: "",
      mobileNumber: "",
      postCode: "",
    });
  };

  const validateUserDetailsErrors = () => {
    resetUserDetailsErrors();

    const isEditForm = mode === "edit";

    let isValid = true;

    // Check for errors
    if (!loginName.trim()) {
      setUserDetailsError((prev) => ({
        ...prev,
        loginName: "Login name is required",
      }));
      isValid = false;
    }

    if (!isEditForm && !password.trim()) {
      setUserDetailsError((prev) => ({
        ...prev,
        password: "Password is required",
      }));
      isValid = false;
    }

    if (!fullName.trim()) {
      setUserDetailsError((prev) => ({
        ...prev,
        fullName: "Full Name is required",
      }));
      isValid = false;
    }

    if (!emailAddress.trim()) {
      setUserDetailsError((prev) => ({ ...prev, email: "Email is required" }));
      isValid = false;
    } else if (!validateEmail(emailAddress)) {
      setUserDetailsError((prev) => ({ ...prev, email: "Invalid Email" }));
      isValid = false;
    }

    if (contactNumber && !validatePhoneNumber(contactNumber)) {
      setUserDetailsError((prev) => ({
        ...prev,
        contactNumber: "Invalid Contact Number",
      }));
      isValid = false;
    }

    if (mobileNumber && !validatePhoneNumber(mobileNumber)) {
      setUserDetailsError((prev) => ({
        ...prev,
        mobileNumber: "Invalid Mobile Number",
      }));
      isValid = false;
    }

    if (postCode && !validateAlphaNumeric(postCode)) {
      setUserDetailsError((prev) => ({
        ...prev,
        postCode: "Invalid post code",
      }));
      isValid = false;
    }

    // Return the overall validation result
    return isValid;
  };

  // User Controls Errors
  const [userControlsErrors, setUserControlsErrors] = useState({
    userType: "",
    userRole: "",
    garageType: "",
  });

  const resetUserControlsErrors = () => {
    setUserControlsErrors({
      userType: "",
      userRole: "",
      garageType: "",
    });
  };

  const validateUserControlsErrors = () => {
    resetUserControlsErrors();

    let isValid = true;

    if (!userType) {
      setUserControlsErrors((prev) => ({
        ...prev,
        userType: "User type is required",
      }));
      isValid = false;
    }

    if (!roles) {
      setUserControlsErrors((prev) => ({
        ...prev,
        userRole: "User role is required",
      }));
      isValid = false;
    }

    const isGrageInUserTypes = userType?.some((userType) => userType.value === "Garage");
    if (isGrageInUserTypes && !garageType) {
      setUserControlsErrors((prev) => ({
        ...prev,
        garageType: "Garage type is required",
      }));
      isValid = false;
    }

    return isValid;
  };

  // Emergency Contact Erros
  const [emergencyContactErrors, setEmergencyContactErrors] = useState({
    mobileNumber: "",
    postCode: "",
  });

  const resetEmergencyContactErrors = () => {
    setEmergencyContactErrors({
      mobileNumber: "",
      postCode: "",
    });
  };

  const validateEmergencyContactErrors = () => {
    resetEmergencyContactErrors();
    let isValid = true;

    if (emergencyMobileNumber && !validatePhoneNumber(emergencyMobileNumber)) {
      setEmergencyContactErrors((prev) => ({
        ...prev,
        mobileNumber: "Invalid mobile number!",
      }));
      isValid = false;
    }

    if (emergencyPostCode && !validateAlphaNumeric(emergencyPostCode)) {
      setEmergencyContactErrors((prev) => ({
        ...prev,
        postCode: "Invalid post code!",
      }));
      isValid = false;
    }

    return isValid;
  };

  // Banking Details Errors
  const [bankingDetailsErrors, setBankingDetailsErrors] = useState({
    hireAccountNumber: "",
    hirePostCode: "",
    repairerAccountNumber: "",
    repairerPostCode: "",
  });

  const resetBankingDetailsErrors = () => {
    setBankingDetailsErrors({
      hireAccountNumber: "",
      hirePostCode: "",
      repairerAccountNumber: "",
      repairerPostCode: "",
    });
  };

  const validateBankingDetailsErrors = () => {
    resetBankingDetailsErrors();
    let isValid = true;

    // if (accountNumber && !validateNumericInput(accountNumber)) {
    //   setBankingDetailsErrors((prev) => ({
    //     ...prev,
    //     hireAccountNumber: "Invalid account number!",
    //   }));
    //   isValid = false;
    // }

    // if (bankingPostCode && !validateAlphaNumeric(bankingPostCode)) {
    //   setBankingDetailsErrors((prev) => ({
    //     ...prev,
    //     hirePostCode: "Invalid post code!",
    //   }));
    //   isValid = false;
    // }

    // if (repairerAccountNumber && !validateNumericInput(repairerAccountNumber)) {
    //   setBankingDetailsErrors((prev) => ({
    //     ...prev,
    //     repairerAccountNumber: "Invalid account number!",
    //   }));
    //   isValid = false;
    // }

    // if (repairerPostCode && !validateAlphaNumeric(repairerPostCode)) {
    //   setBankingDetailsErrors((prev) => ({
    //     ...prev,
    //     repairerPostCode: "Invalid post code!",
    //   }));
    //   isValid = false;
    // }

    return isValid;
  };

  // Additional Errors
  const [additionalErrors, setAdditionalErrors] = useState({
    buyingPriceAdult: "",
    prestigeHire: "",
    buyingPriceMinor: "",
    pcoOrTaxi: "",
    rtamib: "",
    motorbike: "",
    standardHire: "",
    sourcePaymentDays: "",
  });

  const resetAdditionalErrors = () => {
    setAdditionalErrors({
      buyingPriceAdult: "",
      prestigeHire: "",
      buyingPriceMinor: "",
      pcoOrTaxi: "",
      rtamib: "",
      motorbike: "",
      standardHire: "",
      sourcePaymentDays: "",
    });
  };

  const validateAdditionalErrors = () => {
    resetAdditionalErrors();
    let isValid = true;

    if (buyingPriceAdult && !validateDecimalInput(buyingPriceAdult)) {
      setAdditionalErrors((prev) => ({
        ...prev,
        buyingPriceAdult: "Invalid buying price!",
      }));
      isValid = false;
    }

    if (prestigeHire && !validateDecimalInput(prestigeHire)) {
      setAdditionalErrors((prev) => ({
        ...prev,
        prestigeHire: "Invalid prestige hire!",
      }));
      isValid = false;
    }

    if (buyingPriceMinor && !validateDecimalInput(buyingPriceMinor)) {
      setAdditionalErrors((prev) => ({
        ...prev,
        buyingPriceMinor: "Invalid price minor!",
      }));
      isValid = false;
    }

    if (pcoOrTaxi && !validateDecimalInput(pcoOrTaxi)) {
      setAdditionalErrors((prev) => ({
        ...prev,
        pcoOrTaxi: "Invalid PCO/Taxi!",
      }));
      isValid = false;
    }

    if (rtamib && !validateDecimalInput(rtamib)) {
      setAdditionalErrors((prev) => ({
        ...prev,
        rtamib: "Invalid RTAMIB!",
      }));
      isValid = false;
    }

    if (motorbike && !validateDecimalInput(motorbike)) {
      setAdditionalErrors((prev) => ({
        ...prev,
        motorbike: "Invalid motorbike!",
      }));
      isValid = false;
    }

    if (standardHire && !validateDecimalInput(standardHire)) {
      setAdditionalErrors((prev) => ({
        ...prev,
        standardHire: "Invalid standard hire!",
      }));
      isValid = false;
    }

    if (sourcePaymentDays && !validateDecimalInput(sourcePaymentDays)) {
      setAdditionalErrors((prev) => ({
        ...prev,
        sourcePaymentDays: "Invalid source payment days!",
      }));
      isValid = false;
    }

    return isValid;
  };

  // Submit Handlers
  const userDetailsSubmitHandler = async () => {
    if (!validateUserDetailsErrors()) {
      return false;
    }

    try {
      const submitData = {
        login_name: loginName,
        status: status === "active" ? 1 : 0,
        full_name: fullName,
        email: emailAddress,
        mobile_number: mobileNumber,
        contact_number: contactNumber,
        address: address,
        post_code: postCode,
        occupation: occupation,
        vat_registered: isVatRegistered?.value,
        vat_registration_no: vatRegNumber,
      };

      if (latitude) submitData.latitude = latitude;
      if (longitude) submitData.longitude = longitude;

      if (isBase64Image(userImage)) {
        submitData.user_image = userImage;
      }

      if (password.trim()) {
        submitData.login_password = password;
      }

      if (introducer) {
        submitData.introducer_user_id = +introducer.value;
      }

      const { data } = mode === "edit" || userId === createdUserId ? await instance.put(`/user/edit/${userId}`, submitData) : await instance.post("/user/add", submitData);

      createdUserId = data.data.user_id;
      if (mode === "add") {
        setUserId(data.data.user_id);
        toastr.success("Success", "User created successfully");
      } else {
        toastr.success("Success", "User details updated successfully");
      }
      return true;
    } catch (error) {
      handleError(error);
    }
  };

  const userControlsSubmitHandler = async (permissionsGroup) => {
    if (!validateUserControlsErrors()) {
      return false;
    }

    try {
      const submitData = {
        ip_restricted: machineRestricted ? "Yes" : "No",
        pi_preference: piPrefrence ? "Yes" : "No",
        hire_preference: hirePrefrence ? "Yes" : "No",
        role_id: +roles.value,
        user_type: userType.map((userType) => userType.value),
        permissions: permissionsGroup,
        garage_type: userType?.some(({ value }) => value === "Garage") ? garageType.value : "",
      };

      if (machineRestricted) {
        submitData.ip_group_id = userGroup.value;
      }
      if (piPrefrence) {
        submitData.pi_user_id = forPiPanel.map(({ value }) => +value);
      }
      if (hirePrefrence) {
        submitData.hire_user_id = forHireSupplier.map(({ value }) => +value);
      }

      await instance.put(`/user/updateUserControl/${userId}`, submitData);
      toastr.success("Success", "User permissions updated successfully");
      return true;
    } catch (error) {
      handleError(error);
    }
  };

  const emergencyContectSubmitHandler = async () => {
    if (!validateEmergencyContactErrors()) {
      return false;
    }

    try {
      const submitData = {
        emergency_contact_name: emergencyContactName,
        emergency_contact_number: emergencyMobileNumber,
        emergency_contact_relation: relationship,
        emergency_contact_recommended_by: recommendedBy,
        emergency_contact_address: emergencyAddress,
        emergency_contact_post_code: emergencyPostCode,
        emergency_contact_due_diligence: dueDiligence,
      };

      await instance.put(`/user/updateEmergencyContact/${userId}`, submitData);

      toastr.success("Success", "Emergency contact updated successfully");
      return true;
    } catch (error) {
      handleError(error);
    }
  };

  const bankingDetailsSubmitHandler = async () => {
    if (!validateBankingDetailsErrors()) {
      return false;
    }

    try {
      const submitData = {
        bank_name: bankName,
        account_number: accountNumber,
        sort_code: bankingPostCode,
        card_name: cardName,
        repairer_bank_name: repairerBankName,
        repairer_account_number: repairerAccountNumber,
        repairer_sort_code: repairerPostCode,
        repairer_card_name: repairerCardName,
      };

      await instance.put(`/user/updateBankDetails/${userId}`, submitData);

      toastr.success("Success", "Banking details updated successfully");
      return true;
    } catch (error) {
      handleError(error);
    }
  };

  const additionalSubmitHandler = async () => {
    if (!validateAdditionalErrors()) {
      return false;
    }

    try {
      const submitData = {
        // vat_registered: isVatRegistered?.label,
        // vat_registration_no: isVatRegistered ? vatRegNumber : "",
      };

      if (buyingPriceAdult) submitData.buy_price_adult = buyingPriceAdult;
      if (buyingPriceMinor) submitData.buy_price_minor = buyingPriceMinor;
      if (rtamib) submitData.rtamib_rate = rtamib;
      if (standardHire) submitData.standard_hire_rate = standardHire;
      if (prestigeHire) submitData.prestige_hire_rate = prestigeHire;
      if (pcoOrTaxi) submitData.pco_taxi_rate = pcoOrTaxi;
      if (motorbike) submitData.motorbike_rate = motorbike;
      if (sourcePaymentDays) submitData.source_payment_days = sourcePaymentDays;

      await instance.put(`/user/updateAdditionalInfo/${userId}`, submitData);
      toastr.success("Success", "Additional details updated successfully");
      return true;
    } catch (error) {
      handleError(error);
    }
  };

  const values = {
    setMode,
    userId,
    setUserId,
    // User Details
    loginName,
    setLoginName,
    fullName,
    setFullName,
    mobileNumber,
    setMobileNumber,

    password,
    setPassword,
    address,
    setAddress,
    occupation,
    setOccupation,
    postCode,
    setPostCode,
    status,
    setStatus,
    userImage,
    setUserImage,
    introducer,
    setIntroducer,
    latitude,
    setLatitude,
    longitude,
    setLongitude,

    // User Control
    roles,
    setRoles,
    piPrefrence,
    setPiPreference,
    hirePrefrence,
    setHirePrefrence,
    machineRestricted,
    setMachineRestricted,
    userGroup,
    setUserGroup,
    userType,
    setUserType,
    garageType,
    setGarageType,
    userControlsPermssions,
    setUserControlsPermssions,

    // Contact Information
    contactNumber,
    setContactNumber,
    emailAddress,
    setEmailAddress,

    // Emergency Contact Details
    emergencyContactName,
    setEmergencyContactName,
    emergencyMobileNumber,
    setEmergencyMobileNumber,
    relationship,
    setRelationship,
    recommendedBy,
    setRecommendedBy,
    emergencyAddress,
    setEmergencyAddress,
    dueDiligence,
    setDueDiligence,
    emergencyPostCode,
    setEmergencyPostCode,

    // Banking Details
    bankName,
    setBankName,
    branch,
    setBranch,
    accountNumber,
    setAccountNumber,
    cardName,
    setCardName,
    bankingPostCode,
    setBankingPostCode,

    // Repairer Banking Details
    repairerBankName,
    setRepairerBankName,
    repairerCardName,
    setRepairerCardName,
    repairerAccountNumber,
    setRepairerAccountNumber,
    repairerPostCode,
    setRepairerPostCode,

    // Attachment
    contractForm,
    setContractForm,
    poa,
    setPoa,
    poi,
    setPoi,
    photograph,
    setPhotograph,
    attachmentTitle,
    setAttachmentTitle,
    attachment,
    setAttachment,
    attachments,
    setAttachments,

    // Additional
    buyingPriceAdult,
    setBuyingPriceAdult,
    prestigeHire,
    setPrestigeHire,
    buyingPriceMinor,
    setBuyingPriceMinor,
    pcoOrTaxi,
    setPcoOrTaxi,
    rtamib,
    setRtamib,
    motorbike,
    setMotorbike,
    standardHire,
    setStandardHire,
    sourcePaymentDays,
    setSourcePaymentDays,
    isVatRegistered,
    setIsVatRegistered,
    vatRegNumber,
    setVatRegNo,
    forPiPanel,
    setForPiPanel,
    forHireSupplier,
    setForHireSupplier,

    // Errors
    // User Details
    userDetailsError,
    setUserDetailsError,
    validateUserDetailsErrors,

    // User Control
    userControlsErrors,
    setUserControlsErrors,
    validateUserControlsErrors,

    // Emergency Contact
    emergencyContactErrors,
    setEmergencyContactErrors,
    validateEmergencyContactErrors,

    // Banking Details
    bankingDetailsErrors,
    setBankingDetailsErrors,
    validateBankingDetailsErrors,

    // Additional
    additionalErrors,
    setAdditionalErrors,
    validateAdditionalErrors,

    // Submit Handlers
    userDetailsSubmitHandler,
    userControlsSubmitHandler,
    emergencyContectSubmitHandler,
    bankingDetailsSubmitHandler,
    additionalSubmitHandler,
  };

  return <userContext.Provider value={values}>{children}</userContext.Provider>;
};

export default UserContextProvider;
