import React, { memo, useEffect, useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { useUserContext } from "../../../context/security/users";
import placeholder from "../../../assets/img/avatars/loginPlaceholder.jfif";
import useError from "../../../hooks/useError";
import instance from "../../../axiosInstance";
import { validateAlphaNumeric, validateEmail } from "../../../utils/validations";
import { AsyncPaginate } from "react-select-async-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import RequiredLabel from "../../UI/forms/RequiredLabel";
import { useDebounce } from "use-debounce";
import { fetchAddressByPostcode } from "../../../utils/forms";
import ContactInput from "../../UI/forms/ContactInput";
import MobileInput from "../../UI/forms/MobileInput";
import Select from "react-select";
import { booleanOptions } from "../../Administration/ClaimForm/ClientDetails";

export const loadForIntroducerOptions = async (search, loadedOptions, { page }) => {
  try {
    const res = await instance.get("/lookups/introducers", {
      params: { search, page, limit: 10 },
    });

    const { data } = res.data;
    const options = data.data.map((role) => ({
      value: role.user_id,
      label: role.full_name,
    }));

    return {
      options,
      hasMore: page < data.total_pages,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    // handleError(error);
    return {
      options: [],
      hasMore: false,
    };
  }
};

const UserDetails = memo(({ isEditForm = false }) => {
  const {
    loginName,
    setLoginName,
    fullName,
    setFullName,
    mobileNumber,
    setMobileNumber,
    password,
    setPassword,
    emailAddress,
    setEmailAddress,
    contactNumber,
    setContactNumber,
    address,
    setAddress,
    occupation,
    setOccupation,
    postCode,
    setPostCode,
    status,
    setStatus,
    userImage,
    setUserImage,
    userDetailsError,
    setUserDetailsError,
    introducer,
    setIntroducer,
    setLatitude,
    setLongitude,
    isVatRegistered,
    setIsVatRegistered,
    vatRegNumber,
    setVatRegNo,
  } = useUserContext();
  const { handleError } = useError();
  const [showPassword, setShowPassword] = useState(false);

  // const [debouncedPostcode] = useDebounce(postCode, 500);

  // const fetchAddress = async (newPostCode) => {
  //   const newAddress = await fetchAddressByPostcode(newPostCode);

  //   if (newAddress !== 404) {
  //     setAddress(newAddress.address);
  //     setLatitude(newAddress.latitude);
  //     setLongitude(newAddress.longitude);
  //   } else {
  //     setAddress("");
  //     setLatitude("");
  //     setLongitude("");
  //   }
  // };

  // useEffect(() => {
  //   if (debouncedPostcode.trim() !== "") {
  //     fetchAddress(debouncedPostcode);
  //   }
  // }, [debouncedPostcode]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleImageClick = () => {
    const fileInput = document.getElementById("imageUpload");
    fileInput && fileInput.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUserImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <RequiredLabel htmlFor="loginName">Login Name</RequiredLabel>
            <Input
              type="text"
              name="loginName"
              autoComplete="off"
              id="loginName"
              value={loginName}
              disabled={isEditForm}
              className={`${userDetailsError.loginName ? "is-invalid" : ""}`}
              onChange={(e) => {
                const value = e.target.value;
                setLoginName(value);
                setUserDetailsError((pre) => ({
                  ...pre,
                  loginName: value.trim() ? "" : "Login name is required",
                }));
              }}
            />
            <small className="text-danger">{userDetailsError.loginName}</small>
          </FormGroup>

          <FormGroup>
            {isEditForm ? <Label for="password">Password</Label> : <RequiredLabel htmlFor="password">Password</RequiredLabel>}
            <InputGroup>
              <Input
                type={showPassword ? "text" : "password"}
                name="password"
                autoComplete="off"
                id="password"
                className={`${userDetailsError.password ? "is-invalid" : ""}`}
                value={password}
                onChange={(e) => {
                  const value = e.target.value;
                  setPassword(value);
                  setUserDetailsError((pre) => ({
                    ...pre,
                    password: !isEditForm && !value ? "Password is required" : "",
                  }));
                }}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText onClick={togglePasswordVisibility}>{showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <small className="text-danger">{userDetailsError.password}</small>
          </FormGroup>

          <RequiredLabel htmlFor="status">Status</RequiredLabel>
          <Col md={6} className="ml-2">
            <FormGroup>
              <Label className="mb-0">
                <Input type="radio" name="status" id="active" checked={status === "active"} onClick={() => setStatus("active")} />
                Active
              </Label>

              <Label className="mb-0 ml-5">
                <Input type="radio" name="status" id="inactive" checked={status === "inActive"} onClick={() => setStatus("inActive")} />
                Inactive
              </Label>
            </FormGroup>
          </Col>
        </Col>

        <Col md={6} className="d-flex justify-content-center">
          <div className="w-200 h-200 mr-2 mb-2 mt-4 cursor-pointer" onClick={handleImageClick}>
            <input type="file" id="imageUpload" accept="image/*" style={{ display: "none" }} onChange={handleImageChange} />
            <img src={userImage || placeholder} loading="lazy" alt="User Image" className="rounded w-full h-full" />
            <div className="text-center w-full">
              <small className="text-danger text-center">Maximum file size 5mb</small>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <RequiredLabel htmlFor="fullName">Full Name</RequiredLabel>
            <Input
              type="text"
              name="fullName"
              id="fullName"
              autoComplete="off"
              className={`${userDetailsError.fullName ? "is-invalid" : ""}`}
              value={fullName}
              onChange={(e) => {
                const value = e.target.value;
                setFullName(value);
                setUserDetailsError((pre) => ({
                  ...pre,
                  fullName: value ? "" : "Full name is required",
                }));
              }}
            />
            <small className="text-danger">{userDetailsError.fullName}</small>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <RequiredLabel htmlFor="email">Email</RequiredLabel>
            <Input
              type="email"
              autoComplete="off"
              name="email"
              id="email"
              className={`${userDetailsError.email ? "is-invalid" : ""}`}
              value={emailAddress}
              onChange={(e) => {
                const value = e.target.value;
                setEmailAddress(value);
                setUserDetailsError((pre) => ({
                  ...pre,
                  email: validateEmail(value) ? "" : "Invalid email address",
                }));
              }}
            />
            <small className="text-danger">{userDetailsError.email}</small>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <ContactInput
            label="Contact Number"
            name="contactNumber"
            autoComplete="off"
            value={contactNumber}
            onChange={setContactNumber}
            isInvalid={userDetailsError.contactNumber}
            setError={[setUserDetailsError, "contactNumber"]}
          />
        </Col>

        <Col md={6}>
          <MobileInput
            name="mobileNumber"
            autoComplete="off"
            label="Mobile Number"
            value={mobileNumber}
            onChange={setMobileNumber}
            isInvalid={userDetailsError.mobileNumber}
            setError={[setUserDetailsError, "mobileNumber"]}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="postCode">Post Code</Label>
            <Input
              type="text"
              autoComplete="off"
              name="postCode"
              id="postCode"
              className={`${userDetailsError.postCode ? "is-invalid" : ""}`}
              value={postCode}
              onChange={(e) => {
                const value = e.target.value;
                setPostCode(value);
                setUserDetailsError((pre) => ({
                  ...pre,
                  postCode: value && !validateAlphaNumeric(value) ? "Invalid post code" : "",
                }));
              }}
            />
            <small className="text-danger">{userDetailsError.postCode}</small>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="address">Address</Label>
            <Input type="text" autoComplete="off" name="address" id="address" value={address} onChange={(e) => setAddress(e.target.value)} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6} sm={6}>
          <FormGroup>
            <Label for="occupation">Occupation</Label>
            <Input type="text" autoComplete="off" name="occupation" id="occupation" value={occupation} onChange={(e) => setOccupation(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={6} sm={6}>
          <FormGroup>
            <Label for="introducer">Introducer</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="introducer"
              id="introducer"
              loadOptions={loadForIntroducerOptions}
              additional={{
                page: 1,
              }}
              isClearable
              value={introducer}
              debounceTimeout={300}
              onChange={(selected) => setIntroducer(selected)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="VATRegistered">VAT Registered</Label>
            <Select
              id="VATRegistered"
              name="VATRegistered"
              className="basic-single"
              classNamePrefix="select"
              value={isVatRegistered}
              onChange={(value) => setIsVatRegistered(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        {isVatRegistered?.label === "Yes" && (
          <Col md={6}>
            <FormGroup>
              <Label for="vatRegNo">VAT Registered No</Label>
              <Input type="text" autoComplete="off" name="vatRegNo" id="vatRegNo" value={vatRegNumber} onChange={(e) => setVatRegNo(e.target.value)} />
            </FormGroup>
          </Col>
        )}
      </Row>
    </Form>
  );
});

export default UserDetails;
