import React, { memo, useEffect, useState } from "react";
import { ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Spinner, Table } from "reactstrap";
import instance from "../../axiosInstance";
import useError from "../../hooks/useError";
import { toDateAndTimeObj, toYYYYMMDD } from "../../utils/forms";

export const NotesItem = memo(({ description, updatedAt }) => (
  <ListGroupItem>
    <div className="text-muted">{toDateAndTimeObj(updatedAt)}</div>
    <div>{description}</div>
  </ListGroupItem>
));

const Notification = memo(({ id, title, firstname, middlename, lastname }) => {
  const { handleError } = useError();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const [notes, setNotes] = useState(null);
  const [isNotesFetching, setIsNotesFetching] = useState(false);

  const getNotes = async () => {
    try {
      setIsNotesFetching(true);
      const { data } = await instance.get(`/dashboard/deoCaseNotes/${id}`);
      setNotes(data.data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsNotesFetching(false);
    }
  };

  useEffect(() => {
    if (modalIsOpen && !notes) {
      getNotes();
    }
  }, [id, modalIsOpen]);

  return (
    <ListGroupItem className="cursor-pointer">
      <div className="d-flex align-items-center justify-content-between">
        <span>
          NASL-{id} {title} {firstname} {middlename} {lastname}
        </span>
        <span className="text-underline" onClick={toggleModal}>
          View Details
        </span>
      </div>

      <Modal isOpen={modalIsOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Details</ModalHeader>
        <ModalBody>
          {isNotesFetching && (
            <div className="d-flex justify-content-center py-5">
              <Spinner color="primary" />
            </div>
          )}

          {notes && (
            <>
              <div className="d-flex justify-content-between">
                <h6>
                  NASL-{id} {title} {firstname} {middlename} {lastname}
                </h6>

                <h6>{notes?.vehicle_registration_no}</h6>
              </div>

              <Table responsive striped>
                <thead>
                  <tr>
                    <th scope="col">Type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Payment</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>

                <tbody>
                  {notes?.serviceStatus?.map((service) => (
                    <tr>
                      <td>{service.service_type}</td>
                      <td>{service.current_status}</td>
                      <td>{toYYYYMMDD(service.source_paid_date, true) || "-"}</td>
                      <td>{service.source_paid_status === "Yes" || service.source_paid_status === "1" ? "Paid" : "Unpaid"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <h6>Notes</h6>

              <ListGroup flush>
                {notes?.notes?.map((note) => (
                  <NotesItem key={note.case_note_id} description={note.description} updatedAt={note.updated_at} />
                ))}
              </ListGroup>
            </>
          )}
        </ModalBody>
      </Modal>
    </ListGroupItem>
  );
});

export default Notification;
