import React, { memo, useEffect } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useUserContext } from "../../../context/security/users";
import { validateAlphaNumeric } from "../../../utils/validations";
import { fetchAddressByPostcode } from "../../../utils/forms";
import { useDebounce } from "use-debounce";
import MobileInput from "../../UI/forms/MobileInput";

const ContactInformation = memo(() => {
  const {
    // Emergency Contact Details
    emergencyContactName,
    setEmergencyContactName,
    emergencyMobileNumber,
    setEmergencyMobileNumber,
    relationship,
    setRelationship,
    recommendedBy,
    setRecommendedBy,
    emergencyAddress,
    setEmergencyAddress,
    dueDiligence,
    setDueDiligence,
    emergencyPostCode,
    setEmergencyPostCode,
    emergencyContactErrors,
    setEmergencyContactErrors,
  } = useUserContext();

  const [debouncedPostcode] = useDebounce(emergencyPostCode, 500);

  // const fetchAddress = async (newPostCode) => {
  //   const newAddress = await fetchAddressByPostcode(newPostCode);

  //   if (newAddress !== 404) {
  //     setEmergencyAddress(newAddress.address);
  //   } else {
  //     setEmergencyAddress("");
  //   }
  // };

  // useEffect(() => {
  //   if (debouncedPostcode.trim() !== "") {
  //     fetchAddress(debouncedPostcode);
  //   }
  // }, [debouncedPostcode]);

  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="emergencyContactName">Contact Name</Label>
            <Input type="text" autoComplete="off" name="emergencyContactName" id="emergencyContactName" value={emergencyContactName} onChange={(e) => setEmergencyContactName(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <MobileInput
            label="Mobile Number"
            name="emergencyMobileNumber"
            value={emergencyMobileNumber}
            onChange={setEmergencyMobileNumber}
            isInvalid={emergencyContactErrors.mobileNumber}
            setError={[setEmergencyContactErrors, "mobileNumber"]}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="relationship">Relation</Label>
            <Input type="text" autoComplete="off" name="relationship" id="relationship" value={relationship} onChange={(e) => setRelationship(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="recommendedBy">Recommended by</Label>
            <Input type="text" autoComplete="off" name="recommendedBy" id="recommendedBy" value={recommendedBy} onChange={(e) => setRecommendedBy(e.target.value)} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="postCode">Post Code</Label>
            <Input
              type="text"
              autoComplete="off"
              name="postCode"
              id="postCode"
              className={`${emergencyContactErrors.postCode && "is-invalid"}`}
              value={emergencyPostCode}
              onChange={(e) => {
                const value = e.target.value;
                setEmergencyPostCode(value);
                setEmergencyContactErrors((pre) => ({
                  ...pre,
                  postCode: value && !validateAlphaNumeric(value) ? "Invalid post code!" : "",
                }));
              }}
            />
            <small className="text-danger">{emergencyContactErrors.postCode}</small>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="address">Address</Label>
            <Input type="text" autoComplete="off" name="address" id="address" value={emergencyAddress} onChange={(e) => setEmergencyAddress(e.target.value)} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="dueDiligence">Due Diligence</Label>
            <Col md={6} className="ml-2">
              <FormGroup>
                <Label className="mb-0">
                  <Input type="radio" name="dueDiligence" id="Complete" checked={dueDiligence === "Complete"} onClick={(e) => setDueDiligence("Complete")} />
                  Complete
                </Label>
                <br />
                <Label className="mb-0">
                  <Input type="radio" name="dueDiligence" id="Incomplete" checked={dueDiligence === "Incomplete"} onClick={(e) => setDueDiligence("Incomplete")} />
                  Incomplete
                </Label>
              </FormGroup>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
});

export default ContactInformation;
