import React, { useEffect, useState, useRef } from "react";
import { Input, Spinner, Table } from "reactstrap";
import useError from "../../hooks/useError";
import { useDebounce } from "use-debounce";
import instance from "../../axiosInstance";
import TableFeedback from "./TableFeedback";
import { Link, useHistory } from "react-router-dom";
import { useProfileContext } from "../../context/profile";

const overlayStyles = {
  position: "absolute",
  top: "100%",
  left: 20,
  right: 20,
  width: "96%",
  backgroundColor: "#fff",
  padding: "10px 2rem",
  zIndex: 100,
  borderRadius: 5,
  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)", // Adding shadow
};

const NavbarSearch = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const { handleError } = useError();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [isLoading, setIsLoading] = useState(false);
  const [cases, setCases] = useState([]);
  const overlayRef = useRef(null);
  const { profile } = useProfileContext();

  const permissions = profile?.permission?.case;

  const history = useHistory();

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);
  };

  const getNewCases = async () => {
    try {
      setIsLoading(true);
      const res = await instance.get("/case/lists?page=1&limit=50", {
        params: {
          limit: 10,
          search: searchTerm,
        },
      });

      const data = res.data.data;

      setCases(data.data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm.trim()) {
      setShowOverlay(true);
      getNewCases();
    } else {
      setShowOverlay(false);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setShowOverlay(false);
      }
    };

    const handleEscapePress = (event) => {
      if (event.key === "Escape") {
        setShowOverlay(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapePress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapePress);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setShowOverlay(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCaseClick = (caseId) => {
    setShowOverlay(false);
    history.replace(`/administration/cases/${caseId}/edit`);
  };

  return (
    <div>
      <Input type="text" placeholder="Search..." aria-label="Search" className="form-control-lite" onChange={handleInputChange} onClick={() => searchTerm.trim() && setShowOverlay(true)} />
      {showOverlay && permissions?.lists ? (
        <div ref={overlayRef} style={overlayStyles}>
          <Table responsive striped>
            <thead>
              <tr>
                <th scope="col" className="p-1">
                  ID
                </th>
                <th scope="col" className="p-1">
                  Client Name
                </th>
                <th scope="col" className="p-1">
                  Phone No
                </th>
                <th scope="col" className="p-1">
                  Mobile No
                </th>
                <th scope="col" className="p-1">
                  Vehicle Reg No
                </th>
              </tr>
            </thead>

            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan="4" className="text-center">
                    <Spinner color="primary" style={{ width: "1rem", height: "1rem" }} />
                  </td>
                </tr>
              )}
              {cases.length === 0 && !isLoading && <TableFeedback message="No cases found!" colSpan="4" />}

              {cases.map((item) => (
                <tr key={item.case_id}>
                  <td className="p-1">
                    {permissions?.view ? (
                      <span className="custom-link-underline mr-2 cursor-pointer" onClick={() => handleCaseClick(item.case_id)}>
                        {item.case_id}
                      </span>
                    ) : (
                      item.case_id
                    )}
                  </td>
                  <td className="p-1">{item.client_name || "-"}</td>
                  <td className="p-1">{item.phone_no || "-"}</td>
                  <td className="p-1">{item.mobile_no || "-"}</td>
                  <td className="p-1">{item.registration_no || "-"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : null}
    </div>
  );
};

export default NavbarSearch;
